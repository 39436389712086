import React, { Component } from 'react'
import axios from 'axios'


class Halo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
        }
    }
    componentDidMount = () => {

        axios({
            url: 'https://api-ap-northeast-1.graphcms.com/v2/ckibrbrhpi9j701xth4upbvyj/master',
            method: 'post',
            data: {
                query: `
                query MyQuery {
                    tamus {
                      id
                      alamat
                      dari
                      hadir
                      jumlahhadir
                      kepada
                      pesan {
                        text
                      }
                    }
                  }
                                  
                `
            }
        }).then((result) => {
            console.log(result.data)
        });
    }


    render() {


        return (
            <p>
                Halo
            </p>
        )
    }
}

export default Halo